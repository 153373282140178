import "../../../assets/styles/documents.scss";
import ColumnsPane from "./ColumnsPane";
import { SearchInput } from "./SearchInput";
import { Filters } from "./Filters";

/**
 * Documents page - renders import, queue and columns pane
 *
 * @function
 *
 * @returns React JSX
 */
const Documents = () => {
  return (
    <div id="documents-board">
      <div className="right-panel">
        <div>
          <h2>
            <b className="pane-title">Elemendar’s reports</b>
          </h2>
        </div>
        <div className="search-and-filters">
          <SearchInput />
          <Filters />
        </div>
        <ColumnsPane />
      </div>
    </div>
  );
};

export default Documents;
