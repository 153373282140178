import {
  DeleteOutlined,
  LikeOutlined,
  DislikeOutlined,
} from "@ant-design/icons";
import { batch } from "react-redux";
import { entityStatus } from "../../../constants";
import events from "../../../constants/analyticEvents";
import api from "../../../constants/api";
import {
  addProcessingEntities,
  removeSlideInView,
} from "../../../store/actions";
import {
  acceptBulkEntitiesRequest,
  rejectBulkEntitiesRequest,
  removeManyRelationshipsRequest,
} from "../../../store/actions/apiRequests";
import { confirmMultipleEntitiesStatus } from "../../helpers/analysis";
import infoMessages from "../../messages/infoMessages";
import Mixpanel from "../../services/Mixpanel";

export const getRejectEntityConfirmOptions = (
  documentId,
  entityId,
  relationsIndices = [],
  rejectEntityRequest
) => {
  const count = relationsIndices.length;
  const appendMsg = !!count
    ? ` and ${count} relationship${count > 1 ? "s" : ""}`
    : "";

  return {
    icon: <DislikeOutlined className="f-red" />,
    title: (
      <h4 style={{ maxWidth: "300px" }}>
        This action will remove the selected entity{appendMsg}.
      </h4>
    ),
    okText: infoMessages.confirm,
    cancelText: infoMessages.cancel,
    onConfirm: () =>
      rejectEntityRequest(`${api.DOCUMENTS}/${documentId}/entity/${entityId}`),
    onCancel: () => {},
  };
};

export const getAcceptEntityConfirmOptions = (
  documentId,
  entityId,
  acceptEntityRequest
) => {
  return {
    icon: <LikeOutlined className="f-green-d" />,
    title: (
      <h4 style={{ maxWidth: "300px" }}>
        This action will confirm that the selected entity was correctly
        annotated.
      </h4>
    ),
    okText: infoMessages.confirm,
    cancelText: infoMessages.cancel,
    onConfirm: () =>
      acceptEntityRequest(`${api.DOCUMENTS}/${documentId}/entity/${entityId}`, {
        approve: true,
      }),
    onCancel: () => {},
  };
};

export const removeRelationshipPopupView = (
  documentId,
  selected,
  relatedEntities,
  dispatch
) => {
  const { remove, count_selected, relationship } = infoMessages;

  return {
    title: `${remove} ${count_selected.replace(
      ":count:",
      selected.size
    )} ${relationship}?`,
    icon: <DeleteOutlined style={{ color: "#d3556f" }} />,
    onConfirm: (e) => {
      e.stopPropagation();
      let processingEntities = {};
      relatedEntities.ids.forEach((id) => (processingEntities[id] = true));
      batch(() => {
        dispatch(
          removeManyRelationshipsRequest(
            documentId,
            [...selected],
            relatedEntities.ids.join(",")
          )
        );
        dispatch(addProcessingEntities(processingEntities));
        dispatch(removeSlideInView());
      });
    },
    onCancel: () => {},
  };
};

export const removeMultipleRelationshipsWarning = (
  documentId,
  relationshipIds,
  dispatch
) => {
  return {
    title: `${infoMessages.remove_selected_relationships}?`,
    icon: <DeleteOutlined style={{ color: "#d3556f" }} />,
    onConfirm: (e) => {
      e.stopPropagation();
      batch(() => {
        dispatch(removeManyRelationshipsRequest(documentId, relationshipIds));
      });
    },
    onCancel: () => {},
  };
};

export const bulkUpdateEntitiesConfirmOptions = (
  selection,
  documentId,
  status,
  bulkUpdateRequest
) => ({
  title: (
    <>
      You are about to confirm <strong>{selection.length}</strong> entities as{" "}
      <strong>{status}</strong> <br />
      <br />
      Do you want to continue?
    </>
  ),

  onConfirm: (e) => {
    confirmMultipleEntitiesStatus(
      documentId,
      selection,
      status,
      bulkUpdateRequest
    );
    Mixpanel.track(
      status === entityStatus.ACCEPTED
        ? events.ACCEPT_ALL_ENTITIES
        : events.REJECT_ALL_ENTITIES,
      { documentId, [`total-${status}-entities`]: selection.length }
    );
  },
  onCancel: () => {},
});

export const confirmGroupPopupWarning = (
  documentId,
  entityIds,
  status,
  sendRequest
) => ({
  title: (
    <>
      All entities in this group will be <strong>{status}.</strong> <br />
      <br />
      Do you want to continue?
    </>
  ),

  onConfirm: (e) => {
    e.stopPropagation();
    confirmMultipleEntitiesStatus(documentId, entityIds, status, sendRequest);
  },
  onCancel: () => {},
});

export const rejectNodeGroupConfirmation = (
  documentId,
  entityIds,
  dispatch
) => ({
  title: infoMessages.reject_all_entities_in_group,

  onConfirm: () => dispatch(rejectBulkEntitiesRequest(documentId, entityIds)),
  onCancel: () => {},
});

export const acceptNodeGroupConfirmation = (
  documentId,
  entityIds,
  dispatch
) => ({
  title: infoMessages.accept_all_entities_in_group,

  onConfirm: () => dispatch(acceptBulkEntitiesRequest(documentId, entityIds)),
  onCancel: () => {},
});
