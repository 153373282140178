import { useDrop } from "react-dnd";
import { EllipsisOutlined } from "@ant-design/icons";
import {
  documentColumnCountMapper,
  documentColumns,
  documentAccessLevels,
  dragItems,
  documentFilters,
  documentStatus,
} from "../../../constants";
import EmptyContent from "../../common/widgets/EmptyContent";
import DocumentCard from "./DocumentCard";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../../constants/api";
import { useDocumentsUpdate } from "../../../hooks/useDocumentsUpdate";
import { useDocumentContext } from "../../../contexts/DocumentContext";
import useAxios from "../../../hooks/useAxios";
import { handleDrop, handleDocumentStatusUpdate } from "../../../utils/helpers";
import { useColumnsReload } from "../../../hooks/useReload";
import SkeletonLoader from "../../common/loaders/SkeletonLoader";
import ScrollVirtualizer from "./ScrollVirtualizer";
import Checkbox from "antd/lib/checkbox/Checkbox";
import ActionTip from "../../common/widgets/ActionTip";
import { Dropdown, Menu } from "antd";
import { debounce } from "lodash";

const Column = ({ type }) => {
  const { title, color, loaderRepeat } = documentColumns[type];
  const [reloadColumns, setReloadColumns] = useState([]);

  const [{ loading, result }, fetchData] = useAxios({});

  const [{ result: updateRes }, updateDocumentRequest] = useAxios(null, "PUT");
  const { documentData, setDocumentData } = useDocumentContext();
  const { search_string, creator_user_id, collection_id, access_level } =
    documentData;

  useDocumentsUpdate(result, type);
  useColumnsReload(updateRes, reloadColumns);

  const [documents, setDocuments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [{ page, size }, setQuery] = useState({ page: 1, size: 50 });

  const isEmpty = !documents || !documents.length;

  useEffect(() => {
    fetchData(
      `${api.DOCUMENT_API}?status=${type}&page_number=${page}&results_per_page=${size}&${documentFilters.SEARCH}=${search_string}&${documentFilters.IMPORTED_BY}=${creator_user_id}&${documentFilters.COLLECTION}=${collection_id}&${documentFilters.ACCESS}=${access_level}`
    );
  }, [
    page,
    type,
    size,
    fetchData,
    search_string,
    creator_user_id,
    collection_id,
    access_level,
  ]);
  useEffect(() => {
    setDocuments(documentData[type]);
  }, [documentData]);

  useEffect(() => {
    if (result?.data?.total_count) {
      setTotalCount(result.data.total_count);
    }
  }, [result]);

  const [, drop] = useDrop({
    accept: dragItems.CARD,
    drop: ({ index, document }) => {
      handleDrop(index, document.status, type, setDocumentData);
      setTimeout(() => {
        setReloadColumns([document.status, type]);
        handleDocumentStatusUpdate(
          document.document_id,
          type,
          updateDocumentRequest
        );
      }, 500);

      return { targetStatus: type };
    },

    // canDrop: () => {},
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop(),
    }),
  });

  const handleCheckbox = (accessLevelType) => {
    if (accessLevelType === documentAccessLevels.PRIVATE) {
      setDocumentData((prevState) => ({
        ...prevState,
      }));
    } else {
      setDocumentData((prevState) => ({
        ...prevState,
      }));
    }
  };
  const [documentCount, setdocumentcount] = useState(
    documentData[type + "_count"]
  );
  useEffect(() => {
    setdocumentcount(documentData[type + "_count"]);
  }, [documentData]);
  const documentLabel = documentCount > 1 ? " documents" : " document";

  const nextPage = useCallback(
    debounce(() => {
      const totalPages = Math.ceil(totalCount / size);
      if (page < totalPages) {
        setQuery((q) => ({ ...q, page: q.page + 1 }));
      }
    }, 500),
    [page, totalCount, size]
  );

  return (
    <div className="documents-column" ref={drop}>
      <div className="documents-column-header" style={{ borderColor: color }}>
        <div className="filter-access-level">
          <Dropdown
            onClick={(e) => e.stopPropagation()}
            overlay={
              <Menu>
                <Menu.Item>
                  <ActionTip
                    title={true ? "hide private docs" : "show private docs"}
                  >
                    <Checkbox
                      checked={true}
                      onChange={handleCheckbox.bind(
                        null,
                        documentAccessLevels.PRIVATE
                      )}
                      label="baba"
                    >
                      {"  "}private
                    </Checkbox>
                  </ActionTip>
                </Menu.Item>
                <Menu.Item>
                  <ActionTip
                    title={
                      true ? "hide organisation docs" : "show organisation docs"
                    }
                  >
                    <Checkbox
                      checked={true}
                      onChange={handleCheckbox.bind(
                        null,
                        documentAccessLevels.ORGANISATION
                      )}
                    >
                      {"  "}organisation
                    </Checkbox>
                  </ActionTip>
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
          >
            <ActionTip title="More actions">
              <EllipsisOutlined
                style={{ fontSize: "1rem" }}
                rotate={0}
                onClick={(e) => e.stopPropagation()}
              />
            </ActionTip>
          </Dropdown>
        </div>{" "}
        {title}
        <span className="documents-column-header-documentsCount">
          {documentCount} {documentLabel}
        </span>
      </div>
      <div className="documents-column-body ">
        <SkeletonLoader.DocumentCard
          loading={loading && isEmpty}
          repeat={loaderRepeat}
        />

        {!loading && isEmpty && <EmptyContent />}
        {!isEmpty && (
          <ScrollVirtualizer
            documents={documents}
            nextPage={nextPage} //{() => setQuery((q) => ({ ...q, page: q.page + 1 }))}
            hasMore={documents.length < totalCount}
            currentPage={page}
            isLoading={loading}
            pageSize={size}
          >
            {(virtualRow) => (
              <DocumentCard
                document={documents[virtualRow.index]}
                index={virtualRow.index}
              />
            )}
          </ScrollVirtualizer>
        )}
      </div>
    </div>
  );
};

export default Column;

// Initial code
