import { Menu } from "antd";
import { useSelector } from "react-redux";
import {
  ACCEPT_ACCROSS_DOCUMENT,
  ACCEPT_ALWAYS,
  entityClass,
  REJECT_ACCROSS_DOCUMENT,
  REJECT_ALWAYS,
} from "../../../constants";
import Annotation from "../../../utils/services/Annotation";

export const AcceptEntityMenuItems = ({ onClick }) => {
  return (
    <Menu onClick={onClick} className="annotation-popup " mode="vertical">
      <Menu.Item
        key={ACCEPT_ACCROSS_DOCUMENT}
        className="lh-20 list-item-color-hover"
      >
        <span className="annotation-popup-accept">Accept</span> <br />{" "}
        <small className="annotation-popup-color">
          Accept entity for <i className="annotation-popup-accept">this</i>{" "}
          document
        </small>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={ACCEPT_ALWAYS} className="lh-20 list-item-color-hover">
        <span className="annotation-popup-accept">Accept</span> <br />{" "}
        <small className="annotation-popup-color">
          Create a rule for <i className="annotation-popup-accept">future</i>{" "}
          documents
        </small>
      </Menu.Item>
    </Menu>
  );
};

export const RejectEntityMenuItems = ({ onClick }) => {
  return (
    <Menu onClick={onClick} className="overflowhidden">
      <Menu.Item
        key={REJECT_ACCROSS_DOCUMENT}
        className="lh-20 list-item-color-hover"
      >
        <span className="annotation-popup-accept">Reject</span> <br />{" "}
        <small className="annotation-popup-color">
          Reject accross <i className="annotation-popup-accept">this</i>{" "}
          document
        </small>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key={REJECT_ALWAYS} className="lh-20 list-item-color-hover">
        <span className="annotation-popup-accept">Reject</span> <br />{" "}
        <small className="annotation-popup-color">
          Create a rule for <i className="annotation-popup-accept">future</i>{" "}
          documents
        </small>
      </Menu.Item>
    </Menu>
  );
};

export const SingleEntityAnnotation = ({ entityId }) => {
  const analysis = useSelector(({ analysis }) => analysis);

  const entity = analysis.entitiesByIds[entityId];

  let blockText = analysis.blocks[entity.block_idx].text;
  if (entity.class === entityClass.TABLE) {
    const { table_idx, column_idx, row_idx } = entity;
    blockText = analysis.tables[table_idx][row_idx][column_idx];
  }

  const annotatedText = Annotation.singleNode(blockText, entity);
  return <>{annotatedText}</>;
};
