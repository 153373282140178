import { Progress } from "antd";
import Group from "../../../assets/svgs/entities/Group.svg";
import GroupUp from "../../../assets/svgs/entities/GroupUp.svg";

const StatusBar = ({ label, count, total, isOpen, ...restProps }) => {
  const percent = (count * 100) / total;
  return (
    <div className="justify-start">
      <div className="full-width">
        <div className="status-info">
          <span className="status-info-label">{label}</span>
          <span className="status-info-label-counthead">
            <span className="status-info-label-count">{count}</span>{" "}
            {!!percent ? percent.toFixed(1) : 0} %
          </span>
        </div>
        <Progress
          percent={percent}
          size="small"
          showInfo={false}
          strokeColor="#FDB022"
          {...restProps}
          style={{ width: "100%" }}
        />
      </div>
      <div className="status-info-arrowHead">
        {isOpen !== undefined && (
          <span className="status-info-arrow">
            {isOpen ? (
              <img src={GroupUp} alt="down-up" height={14} width={14} />
            ) : (
              <img src={Group} alt="down-arrow" height={14} width={14} />
            )}
          </span>
        )}
      </div>
    </div>
  );
};

export default StatusBar;
