import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Menu } from "antd";
import { FormOutlined } from "@ant-design/icons";
import RelationshipMenu from "./RelationshipMenu";
import SentenceAnnotationMenu from "./SentenceAnnotationMenu";
import EntityAnnotationMenu from "./EntityAnnotationMenu";
import AddRelationshipMenu from "./AddRelationshipMenu";
import ConfirmEntityMenu from "./ConfirmEntityMenu";
import ChangeEntityType from "./ChangeEntityType";
import { showSlideInView } from "../../../../store/actions";
import infoMessages from "../../../../utils/messages/infoMessages";
import { getConfidenceScore } from "../../../../utils/helpers";
import { entityStatus } from "../../../../constants";
import Icon from "@ant-design/icons";
import { entitySvgs } from "../../../../data/entitiesSvg";
import { entityProperties } from "../../../../data/entities";
import Edit from "../../../../assets/svgs/entities/edit.svg";
import Target from "../../../../assets/svgs/entities/target.svg";

import { ReactComponent as Relation } from "../../../../assets/svgs/entities/relation.svg";
import AddRelation from "../../../../assets/svgs/entities/addrelation.svg";
import AnnotateSentence from "../../../../assets/svgs/entities/annotatesentence.svg";
const TEXT_ENTITY_ANNOTATION = "TEXT_ENTITY_ANNOTATION";
const SENTENCE_ENTITY_ANNOTATION = "SENTENCE_ENTITY_ANNOTATION";
const ADD_RELATIONSHIP = "ADD_RELATIONSHIP";
const UPDATE_RELATIONSHIP = "UPDATE_RELATIONSHIP";
const CONFIRM_ENTITY = "CONFIRM_ENTITY";
const CHANGE_ENTITY_TYPE = "CHANGE_ENTITY_TYPE";

const EntitySubtype = ({ type, sub_type }) => {
  return (
    <div className="entity-subtype-wrapper">
      <Icon
        component={() => (
          <img
            src={entitySvgs[type]}
            width={22}
            height={22}
            alt="entity type"
          />
        )}
      />
      <div className="entity-subtype-text-group">
        <span className="entity-subtype-title">
          {entityProperties[type]?.label2 || entityProperties[type]?.label}
        </span>
      </div>
    </div>
  );
};

const ConfidenceScore = ({ score }) => {
  return (
    <div className="confidence-score">
      <span className="confidence-score-title">
        Confidence Score:{" "}
        <span className="confidence-score-title-highlight"> {score?.text}</span>
      </span>
      <div className="bars-wrapper">
        <span className={score?.color}></span>
        <span className={score?.color}></span>
        <span className={score?.color}></span>
      </div>
    </div>
  );
};

const { WAITING } = entityStatus;

const EntityMenu = ({ block, isTable, isMultiline }) => {
  const [subMenuKey, setSubMenuKey] = useState(null);
  const dispatch = useDispatch();

  const { activeSelection } = useSelector(({ selection }) => selection);
  const { entityRelations } = useSelector(({ relationship }) => relationship);
  const { entitiesByIds } = useSelector(({ analysis }) => analysis);

  if (!activeSelection) return null;

  const { isClick, selectedTextEntity, selectedSentenceEntity } =
    activeSelection;

  const selectedEntity = selectedTextEntity || selectedSentenceEntity;

  const {
    status,
    confidence_score,
    sub_properties: { sub_type, tid } = {},
    type,
  } = entitiesByIds?.[selectedEntity?.id] ?? {};

  /* Set key to ensure only one option is expanded at a time */
  const collapseOtherSubMenu = ({ key }) => {
    const activeKey = key === subMenuKey ? null : key;
    setSubMenuKey(activeKey);
  };

  return (
    <Menu mode="vertical" openKeys={[subMenuKey]} className="annotation-popup ">
      {type && (
        <>
          <Menu.SubMenu
            icon={<img src={entitySvgs[type]} width={22} height={22} />}
            className="confidence-score-wrapper"
            key={CONFIRM_ENTITY + "subtype"}
            title={
              <span className="confidence-score-wrapper-label">
                <span className={`${entityProperties[type]?.value}-text-color`}>
                  {entityProperties[type]?.label2 ||
                    entityProperties[type]?.label}
                </span>
                &nbsp; &nbsp;{tid || sub_type}
              </span>
            }
          />
          <Menu.Divider />
        </>
      )}
      {status === WAITING && getConfidenceScore(confidence_score)?.text && (
        <>
          <Menu.SubMenu
            className="confidence-score-wrapper"
            key={CONFIRM_ENTITY + "unique"}
            title={
              <ConfidenceScore score={getConfidenceScore(confidence_score)} />
            }
          />
          <Menu.Divider />
        </>
      )}

      {isClick && selectedEntity && (
        <>
          <ConfirmEntityMenu selectedEntity={selectedEntity} />
        </>
      )}
      {isClick && selectedEntity && (
        <>
          <Menu.SubMenu
            icon={
              <span className="icon">
                <img src={Edit} />
              </span>
            }
            onTitleClick={() =>
              dispatch(
                showSlideInView(
                  ChangeEntityType,
                  { selectedEntity },
                  { title: infoMessages.change_entity }
                )
              )
            }
            key={CHANGE_ENTITY_TYPE}
            title={infoMessages.change_entity}
            className="annotation-popup-infoMessages list-item-color"
            expandIcon={<span className="formoutlinenone" />}
          >
            <ChangeEntityType selectedEntity={selectedEntity} />
          </Menu.SubMenu>
        </>
      )}
      {isClick && selectedEntity && (
        <>
          <Menu.SubMenu
            icon={
              <span className="icon">
                <Relation />
              </span>
            }
            key={UPDATE_RELATIONSHIP}
            title={infoMessages.relationships}
            onTitleClick={() =>
              dispatch(
                showSlideInView(
                  RelationshipMenu,
                  {
                    selectedEntity,
                    relationIndices: entityRelations[selectedEntity.id],
                  },
                  { title: infoMessages.entity_relationship }
                )
              )
            }
            className="annotation-popup-infoMessages list-item-color"
            expandIcon={<span className="formoutlinenone" />}
          >
            <RelationshipMenu
              selectedEntity={selectedEntity}
              relationIndices={entityRelations[selectedEntity.id]}
            />
          </Menu.SubMenu>
        </>
      )}
      {isClick && selectedEntity && (
        <>
          <Menu.SubMenu
            icon={
              <span className="icon">
                <img src={AddRelation} />
              </span>
            }
            onTitleClick={collapseOtherSubMenu}
            key={ADD_RELATIONSHIP}
            title={infoMessages.add_relationship}
            className="annotation-popup-infoMessages list-item-color"
            expandIcon={<span className="formoutlinenone" />}
          >
            <AddRelationshipMenu firstEntity={selectedEntity} />
          </Menu.SubMenu>
        </>
      )}
      {!isClick && (
        <>
          <Menu.SubMenu
            onTitleClick={collapseOtherSubMenu}
            key={TEXT_ENTITY_ANNOTATION}
            icon={
              <span className="icon">
                <img src={Target} />
              </span>
            }
            title={infoMessages.annotate_text}
            className="annotation-popup-infoMessages list-item-color"
          >
            <EntityAnnotationMenu
              activeSelection={activeSelection}
              block={block}
            />
          </Menu.SubMenu>
        </>
      )}

      {!isTable && !isMultiline && (
        <Menu.SubMenu
          onTitleClick={collapseOtherSubMenu}
          key={SENTENCE_ENTITY_ANNOTATION}
          icon={
            <span className="icon">
              <img src={AnnotateSentence} />
            </span>
          }
          title={infoMessages.annotate_sentence}
          className="annotation-popup-infoMessages list-item-color"
        >
          <SentenceAnnotationMenu block={block} />
        </Menu.SubMenu>
      )}
    </Menu>
  );
};

export default EntityMenu;
