import { useDispatch, useSelector } from "react-redux";
import { analysisModes, documentStatus } from "../../../constants";
import { setAnalysisMode } from "../../../store/actions";
import ConfidenceScoreFilter from "./ConfidenceScoreFilter";
import { useState } from "react";

const { ANNOTATION, GRAPH, ExtractionOverview } = analysisModes;
const { COMPLETED } = documentStatus;

/**
 * AnalysisPanel
 *
 * @returns React JSX
 */
const AnalysisPanel = ({ children }) => {
  const {
    analysis: { analysisMode, document },
  } = useSelector((state) => state);
  const dispatch = useDispatch();

  const [overview, setOverview] = useState(false);

  const isCompleted = document.status === COMPLETED;

  const handleModeChange = (mode) => {
    setOverview(false);
    dispatch(setAnalysisMode(mode));
  };

  return (
    <div className="analysis-panel main-header-AnalysisPanel">
      {!overview && (
        <>
          <div
            className="section-card"
            style={{ zIndex: 1, border: "1px solid #EAECF0" }}
          >
            <div
              className="section-card-body justify-between"
              style={{ border: "none" }}
            >
              <div className="section-card-body-btns">
                <div
                  className={`section-card-body-btns-text ${
                    analysisMode === ANNOTATION && "highlight"
                  }`}
                  onClick={() => {
                    handleModeChange(ANNOTATION);
                  }}
                >
                  Annotation
                </div>
                <div
                  className={`section-card-body-btns-text ${
                    analysisMode === GRAPH && "highlight"
                  }`}
                  onClick={() => {
                    handleModeChange(GRAPH);
                  }}
                >
                  Graph
                </div>
                <div
                  className={`section-card-body-btns-text ${
                    analysisMode === ExtractionOverview && "highlight"
                  }`}
                  onClick={() => handleModeChange(ExtractionOverview)}
                >
                  Extraction Overview
                </div>
              </div>
              {analysisMode === ANNOTATION && <ConfidenceScoreFilter />}
            </div>
          </div>
          <div className="full-height">{children}</div>
        </>
      )}
    </div>
  );
};

export default AnalysisPanel;
