import { Button } from "antd";
import SelectInput from "../../../common/forms/SelectInput";
import attackTechniques from "../../../../data/attack_tids_v15_and_disarm.json";

/**
 * TID search dropdown
 *
 * @returns React JSX
 */
const SelectTID = ({
  selectedTID,
  setSelectedTID,
  requestHandler = undefined,
  onClick = undefined,
  showSendButton = false,
  autoFocus = false,
  defaultOpen = true,
}) => {
  return (
    <>
      <span
        className="selecttid"
        onClick={(e) => {
          if (!e.target.closest("#list")) {
            e.stopPropagation();
          }
        }}
      >
        <div className="selecttid-select">
          <SelectInput
            autoFocus={autoFocus}
            defaultOpen={defaultOpen}
            placeholder="Search attack patterns"
            notFoundContent="Not Found"
            onClick={onClick}
            onChange={(techniqueId) => {
              setSelectedTID("");
              setSelectedTID(techniqueId);
              if (selectedTID && requestHandler) requestHandler();
            }}
            options={Object.values(attackTechniques).map(({ tid, name }) => ({
              value: tid,
              label: `[${tid}] ${name}`,
            }))}
          />
        </div>
      </span>
    </>
  );
};

export default SelectTID;
