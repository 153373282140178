import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useVirtual } from "react-virtual";
import "../../../assets/styles/analysis.scss";
import EntityGroupsPane from "./EntityGroupsPane";
import AnalysisPageHeader from "./AnalysisPageHeader";
import DocumentAnnotation from "./DocumentAnnotation.jsx";
import { AnalysisProvider } from "../../../contexts/AnalysisContext";
import { getRangeExtractor } from "../../../utils/helpers/analysis";
import AppErrorBoundary from "../../../errorBoundaries/AppErrorBoundary";
import {
  useAnalysisData,
  useRelationshipProps,
} from "../../../hooks/useAnalysisData";
import { useAnalysisPageState } from "../../../hooks/useResetState";
import { mapEntityRelations } from "../../../utils/helpers/analysis/relationship";
import {
  useAnalysisApiState,
  useClearProcessingEntities,
  useDocumentMetadataState,
  useMetadataState,
} from "../../../hooks/useApiState";
import { getAnalysisDetails } from "../../../store/actions/apiRequests";
import errorMessages from "../../../utils/messages/errorMessages";
import { useErrorPage } from "../../../hooks/useErrorPage";
import AnalysisPanel from "./AnalysisPanel";
import { analysisModes, documentStatus } from "../../../constants";
import GraphPane from "../stixGraph/GraphPane";
import { useManualRuleState } from "../../../hooks/useApiState";
import { useMessageBoxError } from "../../../hooks/useMessageBox";
import DocumentPerformanceOverview from "./DocumentPerformanceOverview";

const { ANNOTATION, GRAPH, ExtractionOverview } = analysisModes;
const { COMPLETED } = documentStatus;

/**
 * Renders document analysis page
 *
 * @function
 * @returns React JSX
 */
const DocumentAnalysis = () => {
  const dispatch = useDispatch();

  const { relationship, analysis, analysisApi } = useSelector((state) => state);
  const analysisDetails = analysisApi.analysisDetails;
  const { analysisMode, document } = analysis;
  const isCompleted = document.status === COMPLETED;

  useErrorPage(analysisDetails.error, [403, 404, 500]);
  useAnalysisData(analysisDetails.result.data);
  useRelationshipProps();
  useAnalysisPageState();
  useAnalysisApiState();
  useMetadataState();
  useDocumentMetadataState();
  useManualRuleState();
  useClearProcessingEntities(analysisDetails);
  useMessageBoxError(analysisDetails.error, errorMessages.oops_reload_retry);

  const initialLoading = analysisDetails.loading && !document.id;

  const { documentId } = useParams(); // get params from current page route

  const parentRef = useRef();
  const groupIndexRef = useRef();

  const { virtualItems, scrollToIndex } = useVirtual({
    size: analysis.blockGroups.length,
    parentRef,
    overscan: 5,
    paddingStart: 15,
    paddingEnd: 15,
    rangeExtractor: useCallback(getRangeExtractor(groupIndexRef), []),
  });

  /* request report analysis data from the
    api when component mounts */
  useEffect(() => {
    dispatch(getAnalysisDetails(documentId));
  }, []);

  return (
    <AppErrorBoundary>
      <Fragment>
        <div id="report-analysis">
          <AnalysisProvider
            value={{
              initialLoading: initialLoading,
              currentLoading: analysisDetails.loading,
              entityRelations: mapEntityRelations(relationship.relations),
              scrollToIndex,
              analysisViewRef: parentRef,
              groupIndexRef,
            }}
          >
            <AnalysisPageHeader
              currentLoading={analysisDetails.loading}
              initialLoading={initialLoading}
            />
            <div id="analysis-body" className="analysis-body">
              <AnalysisPanel>
                {analysisMode === ANNOTATION && (
                  <DocumentAnnotation
                    loading={initialLoading}
                    parentRef={parentRef}
                    virtualItems={virtualItems}
                  />
                )}
                {analysisMode === GRAPH && (
                  <GraphPane docId={document.id} showOptions={false} />
                )}
                {analysisMode === ExtractionOverview && (
                  <DocumentPerformanceOverview
                    documentId={document.id}
                    // hideOverview={() => setOverview(false)}
                    isCompleted={isCompleted}
                  />
                )}
              </AnalysisPanel>
              {(analysisMode === ANNOTATION ||
                analysisMode === ExtractionOverview) && <EntityGroupsPane />}
            </div>
          </AnalysisProvider>
        </div>
      </Fragment>
    </AppErrorBoundary>
  );
};

export default DocumentAnalysis;
