import { useState, useRef, useEffect } from "react";
import { Input, List } from "antd";
import { ReactComponent as Search } from "../../../assets/svgs/entities/search.svg";

/**
 * Custom search bar with options listed below
 *
 * @function
 * @param {{array}} options - List of objects with value and label keys/values
 * @param {{function}} onChange - Function to trigger when an option is selected
 * @param {{object}} props - Optional value for customizing input and list
 * @returns React Virtual DOM
 */
const SearchBarWithOptions = ({ options, onChange, ...props }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [open, setOpen] = useState(false);

  const handleSearch = (e) => {
    setOpen(true);
    setSearchTerm(e.target.value);
  };

  const truncateLabel = (label, maxLength = 20) => {
    return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
  };

  const filteredOptions = options.filter(({ label }) =>
    label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (selectedOption) => {
    if (onChange) {
      onChange(selectedOption);
      setOpen(false);
    }
  };

  return (
    <div className="searchInput">
      <Input
        onChange={handleSearch}
        value={searchTerm}
        prefix={
          <Search
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          />
        }
        className="searchInput-input"
        onFocus={() => setOpen(true)}
        onClick={(e) => {
          setOpen(true);
        }}
        {...props}
      />
      {open && (
        <List
          dataSource={filteredOptions}
          renderItem={({ label, value }) => (
            <List.Item
              onClick={() => handleOptionClick(value)}
              className="hover-list-item"
            >
              {truncateLabel(label)}
            </List.Item>
          )}
          className="list"
          id="list"
        />
      )}
    </div>
  );
};

export default SearchBarWithOptions;
