import {
  ANALYSIS_BLOCKS,
  COMMON_PARENT,
  TEXT_PARENT,
  TEXT_SELECTION_MARKER,
} from "../../constants";
import { selectionError } from "../../constants/error";
import { getRefFromTableBlockId } from "../helpers/analysis";

/**
 * TextSelection class
 *
 * @class
 *
 * Defines instance methods for setting and getting selection properties
 * useful for rendering selected and annotated texts
 */
class TextSelection {
  constructor() {
    this.activeSelection = {};
    this._blockId = "";
    this._multiline = null;
    this._multilineById = {};
  }

  /**
   * getProps - Returns active selection range properties such as
   * selected text, range start and end indices.
   *
   * @method
   *
   * @returns active selection properties
   */
  getProps() {
    const props = {
      selectedText: this._selectedText,
      blockId: this._blockId,
      isClick: this._isClick,
      absoluteStart: this._absoluteStart,
      absoluteEnd: this._absoluteEnd,
      multiline: this._multiline,
      multilineById: this._multilineById,
    };

    return props;
  }

  /**
   * setProps - Set active selection range properties such as
   * selected text, range start and end indices.
   *
   * @method
   *
   */
  setProps() {
    this._setCurrentSelectionRangeObjects();
    const range = this.range;

    // True if it's just a click and no text selected
    this._isClick = range.collapsed;

    if (!this._isClick && (!this._selectedText || !this._selectedText.trim())) {
      throw new Error("No text selected");
    }

    // Parent element of selected text node(s)
    const container = range.commonAncestorContainer;
    const isElementNode = container.nodeType === Node.ELEMENT_NODE;

    const isMultiCellBlocks =
      isElementNode &&
      ["TR", "TBODY", "THEAD", "TABLE"].includes(container.tagName);
    const isMultiSentenceBlocks =
      (isElementNode && container.id === ANALYSIS_BLOCKS) ||
      (container.id || "").startsWith("group-");

    console.log("range", range);

    if (isMultiCellBlocks) {
      this._forMultiCellBlocks();
    } else if (isMultiSentenceBlocks) {
      this._forMultiBlocks();
    } else {
      this._forSingleBlock();
    }

    const nodeMeta = this._nodeMeta;

    // Detect selection over non-sentence entity
    if (!this._isClick && nodeMeta.type) {
      // Don't allow nested selection within non-sentence entity
      if (this._absoluteEnd < nodeMeta.end) {
        throw new Error(selectionError.NESTED_NOT_SUPPORTED);
      }

      // Don't allow interset selection accross existing entity
      if (this._absoluteEnd > nodeMeta.end) {
        throw new Error(selectionError.INTERSET_NOT_SUPPORTED);
      }

      // Don't allow overlay selection on non-sentence entity
      if (
        this._absoluteStart === nodeMeta.start &&
        this._absoluteEnd === nodeMeta.end
      ) {
        return;
      }
    }
  }

  /**
   * _setCurrentSelectionRangeObjects - Set selection and range objects from DOM
   *
   * @method
   *
   */
  _setCurrentSelectionRangeObjects() {
    this.selection = window.getSelection(); // get active selection object
    this._selectedText = this.selection.toString().trim(); // set selected text
    this.range = this.selection.getRangeAt(0).cloneRange(); // get selection range
  }

  /**
   * getNodeIndex - static method to help calculate node index
   *
   * @method
   *
   * @params
   *
   * @returns integer - the node index
   */
  static getNodeIndex(ancestorElement) {
    let nodeIndex = 0;

    // Set index used to specify the element for single node selection
    if ((ancestorElement.dataset.type || "").startsWith(TEXT_PARENT)) {
      nodeIndex = ancestorElement.dataset.index;
    } else if (ancestorElement.id === TEXT_SELECTION_MARKER) {
      nodeIndex = ancestorElement.parentElement.dataset.index;
    } else {
      throw new Error(selectionError.INVALID_SELECTION);
    }

    return nodeIndex;
  }

  /**
   * getAbsoluteOffset - a static method that helps calculate the absolute
   * offset of a node within a text
   *
   * @method
   *
   * @params
   *
   * @returns object containing the absolute offset and meta data
   */
  static getAbsoluteOffset(container, relativeOffset) {
    // Current element
    const element = container.parentElement;

    // Previous sibling
    const previousElement = container.previousElementSibling;

    // Meta data from current element
    const meta = JSON.parse(element.dataset.meta || "{}");

    // Meta data from previous sibling of current element
    const prevMeta =
      !!previousElement && previousElement.dataset.meta
        ? JSON.parse(previousElement.dataset.meta)
        : {};

    // Add relative offset to end position of previous element to get absolute offset
    const absoluteOffset = relativeOffset + (prevMeta.end || meta.start);

    return { absoluteOffset, meta };
  }

  /**
   * _forSingleBlock - an instance helper method setting the properties
   *  for a selection within a single block
   *
   * @method
   *
   */
  _forSingleBlock() {
    const range = this.range;
    const ancestorElement = range.commonAncestorContainer.parentElement;

    this._nodeIndex = TextSelection.getNodeIndex(ancestorElement);
    const start = TextSelection.getAbsoluteOffset(
      range.startContainer,
      range.startOffset
    );
    const end = TextSelection.getAbsoluteOffset(
      range.endContainer,
      range.endOffset
    );
    this._absoluteStart = start.absoluteOffset;
    this._absoluteEnd = end.absoluteOffset;
    this._nodeMeta = start.meta;
  }

  /**
   * _forMultiBlocks - an instance helper method setting the properties
   *  for a selection across multiple blocks
   *
   * @method
   *
   */
  _forMultiBlocks() {
    const range = this.range;

    // for holding list of properties of individual block selection
    const multiline = [];

    // helper data structure for easy access multiline properties
    const multilineById = {};

    // Obtain the common parents of the first and last blocks
    const firstLineElement = range.startContainer.parentElement.closest(
      `.${COMMON_PARENT}`
    );
    const lastLineElement = range.endContainer.parentElement.closest(
      `.${COMMON_PARENT}`
    );

    // Block Id of the first and last selection blocks.
    const firstBlockId = firstLineElement.parentElement.id;
    const lastBlockId = lastLineElement.parentElement.id;
    const firstIndex = +firstBlockId.split("-")[1];
    const lastIndex = +lastBlockId.split("-")[1];

    // Properties of selection within the first block
    const firstLine = {
      sentence_idx: firstIndex,
      start_position: TextSelection.getAbsoluteOffset(
        range.startContainer,
        range.startOffset
      ).absoluteOffset,
      end_position: firstLineElement.textContent.length,
    };

    const end = TextSelection.getAbsoluteOffset(
      range.endContainer,
      range.endOffset
    );
    // Properties of selection within the last block
    const lastLine = {
      sentence_idx: lastIndex,
      start_position: 0,
      end_position: end.absoluteOffset,
    };

    multiline.push(firstLine);
    multilineById[firstBlockId] = firstLine;

    // All properties of selection between the first and the last blocks.
    for (let i = firstIndex + 1; i < lastIndex; i++) {
      const blockElement = document.querySelector(
        `#${ANALYSIS_BLOCKS} *[id^="sentence-${i}"]`
      );

      if (!blockElement) continue;

      const nextLine = {
        sentence_idx: i,
        start_position: 0,
        end_position: blockElement.textContent.length,
      };
      multiline.push(nextLine);
      multilineById[blockElement.id] = nextLine;
    }

    multiline.push(lastLine);
    multilineById[lastBlockId] = lastLine;

    // Properties of the multiline selection.
    // Used the last block selection for context option display
    this._absoluteStart = lastLine.start_position;
    this._absoluteEnd = lastLine.end_position;
    this._blockId = lastBlockId;
    this._nodeIndex = 0;
    this._nodeMeta = end.meta;
    this._multiline = multiline;
    this._multilineById = multilineById;
  }

  /**
   * _forMultiCellBlocks - an instance helper method setting the properties
   *  for a selection across multiple cells in a table
   *
   * @method
   *
   */
  _forMultiCellBlocks() {
    const range = this.range;

    // for holding list of properties of individual block selection
    let multiline = [];

    // Obtain the common parents of the first and last blocks
    const startCellElement = range.startContainer.parentElement.closest(
      `.${COMMON_PARENT}`
    );
    const endCellElement = range.endContainer.parentElement.closest(
      `.${COMMON_PARENT}`
    );

    // Block Id of the first and last selection blocks.
    const startBlockId = startCellElement.parentElement.id;
    const endBlockId = endCellElement.parentElement.id;

    // Properties of start cell selection
    const startCell = {
      ...getRefFromTableBlockId(startBlockId),
      start_position: TextSelection.getAbsoluteOffset(
        range.startContainer,
        range.startOffset
      ).absoluteOffset,
      end_position: startCellElement.textContent.length,
    };

    // End cell selection offset props
    const endOffset = TextSelection.getAbsoluteOffset(
      range.endContainer,
      range.endOffset
    );
    // Properties of end cell selection
    const endCell = {
      ...getRefFromTableBlockId(endBlockId),
      start_position: 0,
      end_position: endOffset.absoluteOffset,
    };

    // Add start cell selection props
    multiline.push(startCell);
    this._multilineById[startBlockId] = startCell;

    // Get current table rows elements
    const tableRowsElements = startCellElement
      .closest("table")
      .getElementsByTagName("tr");

    // Get the start and end row elements for special treatment
    const startRowElement = tableRowsElements[startCell.row_idx];
    const endRowElement = tableRowsElements[endCell.row_idx];

    if (startCell.row_idx === endCell.row_idx) {
      // Handle single row selection
      // Get cell properties between start and end cells
      const cells = this._cellsPropsFromRow(
        startRowElement,
        startCell.column_idx + 1,
        endCell.column_idx - 1
      );
      multiline = multiline.concat(cells);
    } else {
      // Handle multi-rows selection
      // Get properties for cells after start cell in the start row
      const startRowCells = this._cellsPropsFromRow(
        startRowElement,
        startCell.column_idx + 1,
        startRowElement.children.length - 1
      );
      multiline = multiline.concat(startRowCells);

      // Get all cells properties for rows between start and end rows
      for (let row = startCell.row_idx + 1; row < endCell.row_idx; row++) {
        const rowElement = tableRowsElements[row];
        const cells = this._cellsPropsFromRow(
          rowElement,
          0,
          rowElement.children.length - 1
        );
        multiline = multiline.concat(cells);
      }

      // Get properties for cells before end cell in the end row
      const endRowCells = this._cellsPropsFromRow(
        endRowElement,
        0,
        endCell.column_idx - 1
      );
      multiline = multiline.concat(endRowCells);
    }

    multiline.push(endCell);
    this._multilineById[endBlockId] = endCell;

    // Properties of the multiline selection.
    // Uses the last block selection for context option display
    this._absoluteStart = endCell.start_position;
    this._absoluteEnd = endCell.end_position;
    this._blockId = endBlockId;
    this._nodeIndex = 0;
    this._nodeMeta = endOffset.meta;
    this._multiline = multiline;
  }

  /**
   * _cellsPropsFromRow - returns list of cells properties in a row
   *  specified by the startIndex and endIndex range (inclusive)
   *
   * @method
   *
   * @returns arrays of cell properties
   */
  _cellsPropsFromRow(rowElement, startIndex, endIndex) {
    const cellsProps = [];
    for (let col = startIndex; col <= endIndex; col++) {
      const blockNode = rowElement.children[col].firstChild;
      if (!blockNode) continue;
      const cell = {
        ...getRefFromTableBlockId(blockNode.id),
        start_position: 0,
        end_position: blockNode.textContent.length,
      };
      cellsProps.push(cell);
      this._multilineById[blockNode.id] = cell;
    }

    return cellsProps;
  }
}

export default TextSelection;
