import { Button, Popover, Divider, Menu, Avatar, Badge, Modal } from "antd";
import { useAuthContext } from "../../../contexts/AuthContext";
import { useState } from "react";
import { useHistory } from "react-router";
import routes from "../../../constants/routes";
import ActionTip from "../widgets/ActionTip";
import logoutIcon from "../../../assets/svgs/entities/logoutIcon.svg";
import user from "../../../assets/svgs/entities/user.svg";
import searchIcon from "../../../assets/svgs/entities/searchIcon.svg";
import SearchPage from "../../pages/search";

const HeaderOptions = () => {
  const [dropDownOpened, setDropDownOPened] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const { userDetails, auth } = useAuthContext();

  const popoverProps = {
    placement: "bottomRight",
    content: <Options userDetails={userDetails} auth={auth} />,
    trigger: "click",
    borderRadius: "8px",
  };

  if (!(auth && auth.authenticated)) return null;
  return (
    <>
      <ActionTip title="Entity search">
        <Button
          className="header-search-input"
          placeholder="Search by entity..."
          onClick={showModal}
        >
          <img
            src={searchIcon}
            alt="search-icon"
            className="header-search-outlined"
          />
          Search by entity...
        </Button>
      </ActionTip>

      <Modal
        className="main-searchBox"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        closable={false}
        width={827}
        height={64}
        maskStyle={{ backgroundColor: "#101828", opacity: "80%" }}
      >
        <SearchPage />
      </Modal>
      <div className="width">
        <Popover
          {...popoverProps}
          onVisibleChange={(isOpened) => setDropDownOPened(isOpened)}
          overlayClassName="custom-popover"
        >
          <div type="text" size="small" className="avatarmargin">
            <Badge status="success" offset={[-3, 28]}>
              <Avatar className="avatar" size="40">
                {userDetails.fullName
                  .split(" ")
                  .slice(0, 2)
                  .map((word) => word[0])
                  .join("")}
              </Avatar>
            </Badge>
          </div>
        </Popover>
      </div>
    </>
  );
};

export default HeaderOptions;

const Options = ({ userDetails, auth }) => {
  const history = useHistory();

  return (
    <div className="Options">
      <div className="Options-Badge">
        <Badge status="success" offset={[-2, 30]}>
          <Avatar className="avatar" size="large">
            {userDetails.fullName
              .split(" ")
              .slice(0, 2)
              .map((word) => word[0])
              .join("")}
          </Avatar>
        </Badge>

        <div className="Options-name">
          <strong>{userDetails.fullName}</strong> <br />
          <small className="Options-name-email">{userDetails.email}</small>{" "}
          <br />
        </div>
      </div>
      <Divider className="Options-name-divider" />

      <div className="user-options">
        <Menu>
          <Menu.Item
            key={routes.PROFILE}
            className="list-item"
            onClick={() => history.push(routes.PROFILE)}
            icon={<img src={user} alt="user-icon" />}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            key="logout"
            className="list-item"
            onClick={() => auth.logout()}
            icon={<img src={logoutIcon} alt="logout-icon" />}
          >
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
};
