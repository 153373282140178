import React from "react";
import { DatePicker } from "antd";
import AppPopover from "../../common/widgets/AppPopover";
import OptionList from "./OptionList";
import { useSearchContext } from "../../../contexts/SearchContext";
import { docSortBy, staticDateRanges } from "../../../constants";
import { dateRangeText } from "./helpers";
import ChevronDown from "../../../assets/svgs/entities/ChevronDown.svg";

const { RangePicker } = DatePicker;

/**
 * FilterBar - Layout filters for refining search
 *
 * @function
 *
 * @returns React JSX
 */
export const FilterBar = () => {
  const {
    setEntityTypes,
    entityTypes,
    typeOptions,
    statusOptions,
    docStatus,
    setDocStatus,
    dateOptions,
    dateRange,
    customDateRange,
    setDateRange,
    setCustomDateRange,
    sortBy,
    setSortBy,
    sortOptions,
  } = useSearchContext();

  return (
    <div className="justify-between gap-10">
      <div className="justify-start gap-5">
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Type"
              options={typeOptions}
              noEmptySelection
              onSelection={(values) => setEntityTypes(values)}
            />
          }
        >
          <div className="filter-dropdown" onClick={(e) => e.preventDefault()}>
            <span className="filter-dropdown-title">Type</span>
            <span className="filter-dropdown-selected">
              {entityTypes && entityTypes.length < typeOptions.length
                ? entityTypes.length
                : "All"}
            </span>
            <img src={ChevronDown} alt="down-arrow" />
          </div>
        </AppPopover>
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Status"
              options={statusOptions}
              noEmptySelection
              onSelection={(values) => setDocStatus(values)}
            />
          }
        >
          <div className="filter-dropdown" onClick={(e) => e.preventDefault()}>
            <span className="filter-dropdown-title">Status</span>
            <span className="filter-dropdown-selected">
              {docStatus && docStatus.length < statusOptions.length
                ? docStatus.length
                : "All"}
            </span>
            <img src={ChevronDown} alt="down-arrow" />
          </div>
        </AppPopover>
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Date range"
              options={dateOptions}
              singleSelect={true}
              defaultSingle="all_time"
              onSelection={(value) => setDateRange(value)}
              minWidth="300px"
              footer={
                <div>
                  {dateRange === "custom" && (
                    <RangePicker
                      value={customDateRange}
                      onChange={(dr) => setCustomDateRange(dr)}
                    />
                  )}
                </div>
              }
            />
          }
        >
          <div className="filter-dropdown" onClick={(e) => e.preventDefault()}>
            <span className="filter-dropdown-title">Date</span>
            <span className="filter-dropdown-selectedDate">
              {/* {dateRange ? staticDateRanges[dateRange] : "All time"} */}
              {dateRangeText(dateRange, customDateRange)}
            </span>
            <img src={ChevronDown} alt="down-arrow" />
          </div>
        </AppPopover>
      </div>
      <div className="justify-start gap-5">
        <AppPopover
          trigger="click"
          content={
            <OptionList
              title="Sort by"
              options={sortOptions}
              singleSelect={true}
              defaultSingle="most_relevant"
              onSelection={(value) => setSortBy(value)}
            />
          }
        >
          <div className="filter-dropdown" onClick={(e) => e.preventDefault()}>
            <span className="filter-dropdown-title">Most relevant</span>
            <img src={ChevronDown} alt="down-arrow" />
          </div>
        </AppPopover>
      </div>
    </div>
  );
};
