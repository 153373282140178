import { Menu } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { entityStatus } from "../../../../constants/index.js";
import { handleAcceptRejectEntityMenu } from "../helpers.js";
import {
  AcceptEntityMenuItems,
  RejectEntityMenuItems,
} from "../SharedComponents.jsx";

import Thumbup from "../../../../assets/svgs/entities/thumbs-up.svg";
import Thumbdown from "../../../../assets/svgs/entities/thumbs-down.svg";

const { ACCEPTED, REJECTED } = entityStatus;
/**
 * Confirm entity menu
 *
 * @param {object} selectedEntity The selected entity data
 * @returns React JSX
 */
const ConfirmEntityMenu = ({ selectedEntity }) => {
  const dispatch = useDispatch();

  // App analysis state
  const { analysis } = useSelector((state) => state);
  const { document, entitiesByIds } = analysis;

  const currentStatus = entitiesByIds[selectedEntity.id]
    ? entitiesByIds[selectedEntity.id].status
    : "";

  return (
    <div className="accept-or-reject">
      <Menu
        onClick={handleAcceptRejectEntityMenu(
          selectedEntity.id,
          document.id,
          dispatch
        )}
      >
        {currentStatus !== ACCEPTED && (
          <>
            <Menu.SubMenu
              className="list-item-color"
              icon={
                <span className="icon">
                  <img src={Thumbup} />
                </span>
              }
              disabled={currentStatus === ACCEPTED}
              key={ACCEPTED}
              title={<span className="accept-title">Accept</span>}
            >
              <AcceptEntityMenuItems
                onClick={handleAcceptRejectEntityMenu(
                  selectedEntity.id,
                  document.id,
                  dispatch
                )}
              />
            </Menu.SubMenu>
            {/* <Menu.Divider /> */}
          </>
        )}
        <Menu.SubMenu
          className="list-item-color"
          key={REJECTED}
          icon={
            <span className="icon">
              <img src={Thumbdown} />
            </span>
          }
          title={<span className="accept-title">Reject</span>}
        >
          <RejectEntityMenuItems
            onClick={handleAcceptRejectEntityMenu(
              selectedEntity.id,
              document.id,
              dispatch
            )}
          />
        </Menu.SubMenu>
      </Menu>
    </div>
  );
};

export default ConfirmEntityMenu;
